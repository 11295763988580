@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.scrollbar-transparent::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
}

pre:has(div.codeblock) {
    padding: 0;
}

.scroll-down-enter {
    opacity: 0;
}

.scroll-down-enter-active {
    opacity: 1;
    transition: opacity 400ms;
}

.scroll-down-exit {
    opacity: 1;
}

.scroll-down-exit-active {
    opacity: 0;
    transition: opacity 400ms;
}

.transition-transform {
    transition-property: transform;
}

.transform-0 {
    transform: translateX(-100%);
}